.search-result-page{
  padding: 50px 0;
  .search-result-wrapper{
    .header-wrapper{
      margin-bottom: 40px;
    }
    .search-result-list{
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 25px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
    }

    .show-more{
      margin-top: 40px;
      text-align: center;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        margin-top: 20px;
        button {
          width: 100%;
        }
      }
    }
  }
}
