.terms-and-condition-page{
  padding: 50px 0;
  .header-wrapper{
    .default-title{
      margin-bottom: 16px;
      font-size: 36px;
    }
    margin-bottom: 8px;
  }
  .body-wrapper{
    padding-bottom: 20px;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }
  }
}
