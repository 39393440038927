.privacy-policy-page{
  padding: 50px 0;
  .header-wrapper{
    .default-title{
      margin-bottom: 16px;
      font-size: 36px;
    }
    margin-bottom: 8px;
  }
  .body-wrapper{
    padding-bottom: 20px;
    .desc-section{
      margin-bottom: 24px;
      .default-title{
        margin-bottom: 10px;
      }
    }
  }
}
