.app-header {

  position: relative;
  z-index: 100;
  padding: 15px 0px;
  border-bottom: 1px solid #EEEEEE;
  background-color: white;

  .inner-section {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 15px;
    align-items: center;
  }

  .search-wrapper {
    position: relative;
    .box-search {
      .input-group {
        color: $text-color-gray;

        .input-group-text {
          background: $neutral-20;
          border-right: none;
          border: none;
          border-radius: $border-radius-xl;

          .icon {
            color: $text-color-gray;
          }

          &.active{
            border: $dark-border;
          }
        }

        .form-control {
          border-left: none;
          background: $neutral-20;
        }
      }
    }

    .search-result-wrapper{
      display: none;
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 0 8px 20px rgb(0 0 0 / 10%);
      background-color: white;
      top: 3.2rem;
      transition: all 0.5s;
      transform: translateY(-50px);

      &.active{
        display: block;
        opacity: 1;
        z-index: 100;
        transform: translateY(0);
      }

      .search-result-game-list{
        display: flex;
        flex-direction: column;
        grid-gap: 4px;
        padding: 10px;
        line-height: 1.2rem;

        .result-game-item{
          padding: 8px;
          border-radius: 8px;
          cursor: pointer;
          &:hover{
            background-color: $neutral-20;
          }
          .result-game-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;

            .result-game-image-wrapper {
              border-radius: 5px;
              height: 52px;
              width: 52px;
              overflow: hidden;

              .result-game-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
              }
            }

            .result-game-detail-wrapper {
              margin-left: 0.75rem;
              white-space: normal;

              .title {
                font-weight: 500;
                color: $text-color;
              }
            }
          }
        }

        .result-game-more-link {
          text-align: center;
          margin: .75em 0;
          cursor: pointer;
        }
      }
    }
  }

  .action-wrapper {
    text-align: right;
  }

  .profile-wrapper {

    .dropdown-toggle {
      display: inline-block;

      &:after {
        display: none;
      }

      &[aria-expanded="true"] {
        .icon-wrapper {

          .caret {
            transform: rotate(-180deg);
          }
        }
      }

      .icon-wrapper {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 2px 30px;
        border: $default-border;
        border-radius: 0.8rem;

        .caret {
          font-size: 14px;
          @include transition(all, 0.5s, ease);
        }


        @include media-breakpoint-down(md) {
          padding: 2px;
        }
      }
    }

    .dropdown-menu {
      min-width: unset;
      border-radius: 16px;
      padding: 8px;


      hr {
        margin: 6px 1rem;
      }
    }

    .dropdown-item {
      text-align: center;
      border-radius: 16px;
      padding: 11px 17px;
      cursor: pointer;

      &:hover,
      &:active {
        color: var(--bs-dropdown-link-hover-color);
        background-color: $neutral-20;
      }
    }
  }

  @include media-breakpoint-down(md) {

    .inner-section {
      grid-template-columns: 1fr 5fr 1fr;
    }

    .logo-wrapper {
      .logo {
        min-height: 30px;
      }
    }
  }
}
