.app-footer {
  padding: 15px 0px 80px;
  background: white;
  color: $text-color-gray;
  font-size: 14px;
  font-weight: 600;

  .inner-section {
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 40px;
    .section-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      .social-media-wrapper {
        display: flex;
        align-items: center;
        gap: 25px;

        .social-media-item {
          border: $default-border;
          padding: 8px;
          border-radius: 14px
        }
      }
    }
  }

  .link-list {
    @extend .default-list-style;
    display: flex;
    gap: 25px;

    a {
      color: $neutral-70;

      &:hover {
        color: $text-color;
      }
    }
  }

  .copyright-wrapper {
    font-size: 12px;
    text-align: right;
  }

  @include media-breakpoint-down(md) {
    padding: 15px 0px 40px;

    .inner-section {
      gap: 25px;
      .section-wrapper {
        flex-direction: column;
        align-items: flex-start;
        &.column-reverse {
          flex-direction: column-reverse;
        }
      }
    }

    .copyright-wrapper {
      text-align: left;
    }

    .link-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
}
