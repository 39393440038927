.game-list {
  margin-bottom: 50px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;

    .option-list {
      @extend .default-list-style;
      display: flex;
      align-items: center;
      background-color: $neutral-20;
      border-radius: $border-radius-xl;
      border: $default-border;
      padding: 5px;
      gap: 15px;

      overflow-x: scroll;

      .item {
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        display: none;
      }

    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: unset;
    }
  }

  .game-show-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 25px;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }

  .show-more {
    text-align: center;

    @include media-breakpoint-down(md) {

      .btn {
        width: 100%;
      }
    }
  }
}
