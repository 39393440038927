.setting-page{
  .card {
    border-radius: 16px;
  }
  padding: 50px 0;
  .setting-wrapper{
    display: flex;
    gap: 40px;
    .setting-navigation-wrapper{
      .default-title{
        margin-top: 10px;
        letter-spacing: 1px;
      }
      .setting-navigation{
        margin-top: 30px;
        .navigation-item{
          cursor: pointer;
          color: #6B6B6B;
          margin-bottom: 15px;
          &.active{
            color: #111111;
            font-weight: 500;
          }
        }
      }
    }
    .tab-content{
      width: 100%;
    }
    .setting-account-section{
      max-width: 600px;
      .card{
        border-radius: $border-radius-2xl;
        padding: 20px;

        .default-title{
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
    }
    .transaction-history-section{
      .category-wrapper{
        .option-list{
          display: flex;
          align-items: center;
          background-color: $neutral-20;
          border-radius: $border-radius-xl;
          border: $default-border;
          padding: 5px;
          gap: 15px;
          list-style: none;
          overflow-x: scroll;

          .item {
            white-space: nowrap;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .body-wrapper{
        .history-list{
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .no-data-wrapper{
          .card{
            border-radius: $border-radius-2xl;
            padding: 30px;
            .failed-image{
              width: 80px;
              height: 80px;
              margin: auto;
            }
            .card-text{
              margin: 20px 0;
              color: #6B6B6B;
            }
            .btn{
              width: fit-content;
              margin: auto;
            }
            text-align: center;
          }
        }
      }

      .show-more {
        text-align: center;
        margin-top: 30px;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 0;
      .setting-navigation-wrapper{
        .setting-navigation{
          display: flex;
          align-items: center;
          gap: 30px;
        }
      }
      .transaction-history-section{
        .category-wrapper{
          .form-select{
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
