.showcase {
  margin-bottom: 50px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .title {
      font-weight: 700;
      margin: 0px;
      font-size: 28px;
    }

    .slider-control {
      display: flex;
      align-items: center;
      gap: 10px;

      .btn-control {
        border-radius: 12px;
        border: $default-border;
        background-color: transparent;
        color: black;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;


        &:hover {
          background-color: black;
          border: none;

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
