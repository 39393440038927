.article-page{
  padding: 50px 0;
  position: relative;
 

  .header-wrapper{
    text-align: center;
    margin-bottom: 60px;
  }

  .article-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .show-more{
    margin-top: 40px;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
  }

  
}
