.card-game {
  display: block;
  border: $default-border;
  border-radius: 16px;

  .image-wrapper {
    height: 160px;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
    border-bottom: $default-border;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .info-wrapper {
    min-height: 53px;
    padding: 0 12px;

    .title {
      font-weight: 500;
      @include ellipsis(2);
    }
  }
}
