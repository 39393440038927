.auth-layout {
  padding: 50px 0px;
  position: relative;

  .inner-section {
    max-width: 560px;
    margin: auto;
  }

  .box-form {
    background: white;
    border: $default-border;
    border-radius: $border-radius-xl;
    padding: 30px;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }
  }

  .header-form {
    text-align: center;
    margin-bottom: 30px;

    .title {
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 15px;
    }

    .subtitle {
      font-weight: 500;
      margin-bottom: 0px;

      a {
        color: $secondary-main;
      }
    }
  }

  .success-wrapper {
    text-align: center;

    .icon {
      width: 80px;
      height: auto;
      margin-bottom: 30px;
    }

    .title {
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 30px;
    }

    .desc {
    }

    .auto-redirect {
      color: $text-color-gray;
    }
  }
}
