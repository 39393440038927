.default-title {
  font-weight: 600;
  margin: 0px;
  font-size: 28px;
}

.default-link {
  font-weight: 600;
  color: $secondary-main;
  cursor: pointer;

  &:hover {
    color: $secondary-hover;
  }
}

.default-modal {
  .modal-header {
    border: none;
    padding: 15px;
    justify-content: center;

    .modal-title {
      font-size: 18px;
      font-weight: 700;
    }

    .btn-close {
      width: 15px;
      height: 15px;
      padding: 0px;
      box-shadow: none;
      position: absolute;
      right: 25px;
    }
  }

  .amount {
    text-align: center;
    font-weight: 700;
    margin-bottom: 15px;
  }
}
