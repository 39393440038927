.why-info {
  .why-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;

    .item {
      background-color: $neutral-20;
      padding: 24px;
      border-radius: 16px;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }

    .icon {
      max-width: 80px;
      max-height: 80px;
      object-fit: cover;
      margin-bottom: 25px;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
    }

    .desc {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
}
