.card-news {
  border: 1px solid #eeeeee;
  display: block;
  border-radius: 16px;
  background: white;

  &:hover {
    .image-wrapper {
      .image {
        transform: scale(1.1);
      }
    }

    .info-wrapper {
      .title {
        color: $main;
      }
    }
  }

  .image-wrapper {
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 16px;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transform: scale(1);
      @include transition(all, 0.5s, ease);
    }
  }

  .info-wrapper {
    padding: 15px;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: $text-color;
    }

    .timer {
      font-size: 14px;
      color: $text-color-gray;
    }
  }
}
