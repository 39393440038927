.latest-news-wrapper {
  margin-bottom: 50px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .latest-news-list {

  }
}
