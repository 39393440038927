/**
 * MIXINS
 */

@mixin transition ($type, $duration, $format) {
  transition: $type $duration $format;
  -webkit-transition: $type $duration $format;
  -moz-transition: $type $duration $format;
  -o-transition: $type $duration $format;
}

@mixin ellipsis ($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
