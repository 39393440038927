.banner-slider {

  .carousel {
    padding-bottom: 30px;
  }

  .carousel-indicators {
    margin-bottom: 0px;

    [data-bs-target] {
      opacity: 1;
      background-color: #EEEEEE;
      height: 5px;

      &.active {
        background-color: $main;
      }
    }
  }

  .carousel-control-prev, .carousel-control-next {
    width: unset;
    opacity: 1;

    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 36px;
      height: 36px;
      border-radius: 12px;
      border: $default-border;
      background-color: white;
      background-size: 10px;

      @include transition(all, 150ms, ease);

      &:hover {
        border: none;
        background-color: black;
      }
    }

    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23000' d='M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z'%3E%3C/path%3E%3C/svg%3E");


      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23fff' d='M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23000' d='M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z'%3E%3C/path%3E%3C/svg%3E");

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg role='img' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23fff' d='M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z'%3E%3C/path%3E%3C/svg%3E");
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .slider-wrapper {
    .banner {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
