.not-found-page {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .background {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .inner-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: flex-end;

    .content {
      position: relative;
      color: black;
      text-align: center;
      max-width: 550px;
      margin: auto;

      .info {
        position: relative;
        bottom: 60px;
        .robot {
          margin-top: 38px;
          width: 80%;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }

      .title {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 25px;
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 35px;
      }
    }
  }
}
