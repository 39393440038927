/**
 *  VARIABLES
 *
 *  Configure library by adjusting this file
 *
 */

:root {
  --main-color: #4B21EE;
}

// Main Colors
$text-color:            #111111;
$text-color-gray:       #6B6B6B;

$main:                  #4B21EE;
$surface:               #E8E2FD;
$border:                #3F1CC6;
$hover:                 #261077;
$pressed:               #190B4F;
$focus:                 rgba(75, 33, 238, 0.2);
$dark:                  #12073f;
$secondary-main:        #5451DB;
$secondary-surface:     #EEEEFB;
$secondary-hover:       #706EE1;
$success-main:          #79EFBC;
$success-surface:       #E4FCF2;
$success-border:        #5CD6A1;
$success-dark:          #26CF86;
$danger-main:           #ED4B45;
$danger-surface:        #FDEDED;
$warning-main:          #FFB802;
$warning-surface:       #FDF3DB;
$neutral-20:            #F6F6F6;
$neutral-40:            #E0E0E0;
$neutral-70:            #868687;
$bg-main:               #F4F1FD;
$linear-gradient:        linear-gradient(to right, rgb(255,255,255,0.8), rgb(255,255,255,0.6));
$linear-gradient-hover:  linear-gradient(80.63deg, #4B21EE 0%, #74DBC1 98.39%);

// Social colors
$fb:                    rgb(59, 89, 182);
$tw:                    rgb(64, 153, 255);
$googleplus:            rgb(215, 61, 50);
$pinterest:             rgb(203, 32, 39);
$skype:                 rgb(18, 165, 244);
$spotify:               rgb(129, 183, 26);
$instagram:             #4e433c;
$tumblr:                #2b4964;
$vimeo:                 #86b32d;
$soundcloud:            #f76600;
$youtube:               #ff3333;
$linkedin:              #4875b4;
$flickr:                #fe0883;
$foursquare:            #8fd400;
$whatsapp:              #25D366;


$default-box-shadow:            0px 0px 15px -8px #c5c5c5;
$default-border:                1px solid #EEEEEE;
$dark-border:                   1px solid #A5A5A6
