.card-transaction-history{
  .card-header{
    display: flex;
    border-radius: 16px;
    background-color: $bg-main;
    align-items: center;
    justify-content: space-between;
    .status-wrapper{
      display: flex;
      align-items: center;
    }
    .date{
      font-weight: 600;
    }
    .status-item{
      margin-left: 10px;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 6px;
      &.berhasil{
        background-color: #EDFBF2;
        color: #1EC876;
      }
      &.menunggu{
        background-color: #FCF3CF;
        color: #D28D0D;
      }
      &.kedaluwarsa{
        background-color: #EEEEEE;
        color: #898989;
      }
    }
  }
  .card-body{
    background-color: white;
    display: flex;
    gap: 60px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .card-item{

      &.btn-wrapper {
        flex: 1;
        text-align: right;
      }

      .card-item-value{
        font-weight: 600;

        .before-discount {
          display: block;
          font-size: 14px;
          color: $text-color-gray;
          text-decoration: line-through;
          font-weight: 400;
        }

        .after-discount {
          display: block;
          color: $red;
        }
      }
    }
  }

  @include media-breakpoint-down(md){
    .card-body{
      flex-direction: column;
      gap: 8px;
      .card-item{
        display: flex;
        justify-content: space-between;

        &.btn-wrapper {
          justify-content: center;

          .btn {
            min-width: 100px;
          }
        }
      }
    }
  }
}
