body {
  font-family: "Prompt";
  color: $text-color;
}

.app-body {
  min-height: 100vh;

  .floating-chat {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 0;

    .icon {
      width: 85%;
    }
  }
}

.form-control {
  height: 48px;
  background-color: $neutral-20;
  border-radius: $border-radius-xl;
  border: none;
  &.is-invalid:focus {
    box-shadow: none;
  }

  &:focus {
    background-color: $neutral-20;
    border: $dark-border;
    box-shadow: none;
  }
}

.form-control-icon {
  position: relative;

  .icon {
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.inline-form-control {
  display: flex;
  gap: 20px;

  .form-control-wrapper {
    flex: 1;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }
}

.invalid-feedback {
  display: block;
}

.form-label {
  font-weight: 600;
}

.form-note {
  font-size: 12px;
  color: $text-color-gray;
}

.form-select {
  border-radius: $border-radius-xl;
  min-height: 48px;
}

.divider {
  opacity: 0.2;
}

a {
  color: $text-color-gray;
  text-decoration: none;

  &:hover {
    color: $secondary-main;
  }
}

.text-link {
  color: $secondary-main;
}

.password-input-wrapper {
  position: relative;

  .form-control {
    padding-right: 30px;

    &.is-invalid {
      background-image: none;

      & ~ .icon {
        color: $danger-main;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

.custom-radio-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  label {
    input {
      display: none;

      &:checked {
        ~ span {
          background: $success-surface;
          border: 2px solid $success-main;
        }
      }
    }

    span {
      cursor: pointer;
      background: white;
      border: 1px solid $border-color;
      border-radius: 14px;
      width: 100%;
      display: flex;
      min-height: 70px;
      height: 100%;
      padding: 15px 25px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      @include transition(all, 0.5s, ease);

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
}

.payment-radio-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  .radio-input {
    input {
      display: none;

      &:checked {
        ~ .option {
          background: $success-surface;
          border: 2px solid $success-main;
        }
      }
    }

    .option {
      cursor: pointer;
      background: white;
      border: 1px solid $border-color;
      border-radius: 14px;
      width: 100%;
      min-height: 70px;
      height: 100%;
      padding: 15px 25px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      position: relative;
      @include transition(all, 0.5s, ease);

      display: flex;
      flex-direction: column;
      gap: 10px;
      line-height: 1;

      .label {
        color: white;
        font-size: 10px;
        position: absolute;
        top: -1.5px;
        left: -1.5px;
        padding: 5px 10px;
        background: $linear-gradient-hover;
        border-top-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      .discount {
        font-size: 12px;
        font-weight: 600;
        color: $text-color-gray;
      }

      .logo {
        width: 60px;
      }

      .total {
        color: $success-main;
        font-weight: 600;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
  }
}

.voucher-button {
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .text-icon-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 600;

    .check {
      width: 24px;
    }
  }
}

.spinner-wrapper {
  min-height: 500px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-orange {
    color: $main;
  }
}

.tooltip-main {
  .tooltip-inner {
    background-color: white;
    color: black;
    border-radius: 14px;
    box-shadow: 0px 2px 12px 6px rgba(0, 0, 0, 0.12);
  };

  &.bs-tooltip-end {
    .tooltip-arrow::before {
      border-right-color: white;
    };
  };
  &.bs-tooltip-start {
    .tooltip-arrow::before {
      border-left-color: white;
    };
  };
  &.bs-tooltip-top {
    .tooltip-arrow::before {
      border-top-color: white;
    };
  };
  &.bs-tooltip-bottom {
    .tooltip-arrow::before {
      border-bottom-color: white;
    };
  };
};


.modal-content {
  border-radius: 1.5rem;
}