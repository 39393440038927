.purchase-page {
  padding: 70px 0px;

  .content-wrapper {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 30px;
    max-width: 1100px;
    margin: auto;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .detail-wrapper {
        background-color: $neutral-20;
        border: $default-border;
        border-radius: 16px;
        .card-detail {
          padding: 30px;
          border-bottom: $default-border;
          background-color: white;
          border-radius: 16px;
          text-align: center;

          .title {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 30px;
          }

          .thumbnail-wrapper {
            max-width: 70%;
            margin: auto;

            .thumbnail {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center center;
            }
          }
        }

        .payment-term {
          padding: 20px;
          .header-title {
            margin-bottom: 24px;
            .title {
              font-weight: 700;
              font-size: 18px;
            }
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

    .info-section {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .info-box {
        border: 1px solid $border-color;
        border-radius: 16px;
        counter-increment: css-counter 1;

        .header-panel {
          padding: 10px 25px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          gap: 15px;
          border-radius: 16px;
          background-color: $bg-main;

          .number {
            font-weight: 700;
            font-size: 18px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background-color: white;
            border: $default-border;

            &:after {
              content: counter(css-counter);
            }

            @include media-breakpoint-down(md) {
              font-size: 14px;
              width: 25px;
              height: 25px;
            }
          }

          .title {
            font-weight: 500;
            font-size: 18px;
            margin: 0px;

            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }
        }

        .body-panel {
          padding: 25px;
        }
      }
    }
  }
}
