.btn {
  font-size: 16px;
  border: none;
  min-width: 100px;
  min-height: 48px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-xl;

  @include media-breakpoint-down(md) {
    min-width: unset;
  }
}

.btn-main {
  background: $main;
  color: white;
  transition: all;
  transition-property: color;

  &:hover,
  &:focus {
    background: $linear-gradient-hover;
    color: white;
  }
}

.btn-option {
  color: $text-color;
  background: transparent;
  border: none;
  min-height: 40px;
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 10px;

  &.active {
    background: white;
    font-weight: 600;
  }
}

.btn-outline-gray {
  color: $text-color;
  border: $default-border;
  min-height: 40px;
  padding: 5px 15px;
  font-weight: 500;

  &:hover {
    border: $default-border;
    background: $neutral-20;
  }

  &:active, &:focus {
    border: 1px solid $success-main;
    background: $success-surface;
  }
}

.btn-lg {
  min-height: 52px;
}

.btn-md {
  min-height: 48px;
}

.btn-sm {
  min-height: 40px;
}

.btn-full {
  width: 100%;
}
