.article-detail-page{
  padding: 50px 0;
  .header-wrapper{
    text-align: center;
    margin-bottom: 20px;
    .default-title{
      margin: 30px auto;
      max-width: 700px;
    }
    .shares-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .share-item{
        width: 44px;
        height: 44px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: $default-border;
        cursor: pointer;
        &.facebook{
          img {
            filter: invert(40%) sepia(8%) saturate(5498%) hue-rotate(186deg) brightness(89%) contrast(80%);
          }

          &:hover {
            background-color: $fb;

            img {
              filter: none;
            }
          }
        }
        &.whatsapp{
          img {
            filter: invert(62%) sepia(70%) saturate(486%) hue-rotate(90deg) brightness(93%) contrast(92%);
          }

          &:hover {
            background-color: $whatsapp;

            img {
              filter: none;
            }
          }
        }
        &.twitter{

          img {
            filter: invert(48%) sepia(86%) saturate(592%) hue-rotate(165deg) brightness(95%) contrast(99%);
          }

          &:hover {
            background-color: $tw;

            img {
              filter: none;
            }
          }
        }
        &.link{
          &:hover {
            background-color: black;

            img {
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(92deg) brightness(103%) contrast(103%);
            }
          }
        }
      }
    }
  }

  .body-wrapper{
    padding: 40px;
    .banner-image-wrapper{
      width: 100%;
      img{
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .body-container{
      padding: 60px 100px;
    }

    @include media-breakpoint-down(md) {
      padding: 40px 0;
      .body-container{
        padding: 20px 0;
      }
    }
  }

  .related-article-wrapper{
    .default-title{
      margin-bottom: 20px;
    }
  }
}
